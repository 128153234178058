import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import '../assets/scss/main.scss'
import '../assets/css/typography.css'

const Layout = ({ children, location }) => {
  let content

  if (location && location.pathname === '/') {
    content = <div>{children}</div>
  } else {
    content = (
      <div id="wrapper" className="page">
        <div>{children}</div>
      </div>
    )
  }

  const toTitleCase = str => str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();})

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          contentfulSiteSettings {
            siteTitle
            siteSubtitle
          }
        }
      `}
      render={data => {
        const {
            contentfulSiteSettings: { siteTitle, siteSubtitle }
        } = data
        return (
          <>
            <Helmet
              title={toTitleCase(siteTitle)}
              meta={[{ name: 'description', content: toTitleCase(siteSubtitle) }]}
            >
              <html lang="en" />
            </Helmet>
            {content}
          </>
        )
      }}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
